import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=7c563740&"
import script from "./AppHeader.vue?vue&type=script&lang=js&"
export * from "./AppHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModuleMenu: require('/furanet/sites/runnea.pt/web/htdocs/nuxt/components/ModuleMenu.vue').default})
