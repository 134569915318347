import { render, staticRenderFns } from "./ModuleProductPriceOpinion.vue?vue&type=template&id=0d0b9094&"
import script from "./ModuleProductPriceOpinion.vue?vue&type=script&lang=js&"
export * from "./ModuleProductPriceOpinion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModuleHeader: require('/furanet/sites/runnea.pt/web/htdocs/nuxt/components/ModuleHeader.vue').default,ProductCardPriceOpinion: require('/furanet/sites/runnea.pt/web/htdocs/nuxt/components/ProductCardPriceOpinion.vue').default,ModuleFooter: require('/furanet/sites/runnea.pt/web/htdocs/nuxt/components/ModuleFooter.vue').default})
